<template>
  <div class="text-center">
    <v-dialog v-model="fileUploadModalData.dialog" width="500" persistent>
      <template v-slot:activator="{ on, attrs }">
        <v-btn color="primary" class="ml-3" dark v-bind="attrs" v-on="on">
          {{ $t("MEDIA_LIBRARY.file_upload") }}
        </v-btn>
      </template>

      <v-card>
        <v-card-title>
          <v-row>
            <v-col cols="12" sm="12" md="12">
              <span class="headline"
                >{{ $t("MEDIA_LIBRARY.file_upload") }}
              </span>
              <v-btn
                @click="handleCloseFileUploadModalForm"
                icon
                class="close-button"
              >
                <v-icon> mdi-close</v-icon>
              </v-btn>
            </v-col>
          </v-row>
        </v-card-title>

        <v-card-subtitle
          >{{ $t("MEDIA_LIBRARY.file_upload_max_size") }}:
          {{ this.bytesToSize(config.maxFileSize) }}<br />
          {{ $t("MEDIA_LIBRARY.file_upload_max_count") }}:
          {{ config.maxFilesCount }}</v-card-subtitle
        >

        <v-card-text>
          <v-form
            ref="fileUploadForm"
            v-model="fileUploadFormValid"
            lazy-validatio
          >
            <v-row>
              <v-spacer></v-spacer>
              <v-col cols="12" sm="12" md="12">
                <v-file-input
                  small-chips
                  multiple
                  show-size
                  counter
                  :accept="config.accept"
                  v-model="files"
                  :label="$t('MEDIA_LIBRARY.please_select')"
                  :rules="[
                    (value) => {
                      return ruleMaxFiles(value);
                    },
                  ]"
                >
                </v-file-input>
              </v-col>
            </v-row>
          </v-form>
        </v-card-text>

        <v-divider></v-divider>

        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="primary" text @click="handleCloseFileUploadModalForm">
            {{ $t("FORMS.cancel") }}
          </v-btn>
          <v-btn color="primary" @click="handleFileUploadModalForm">
            {{ $t("MEDIA_LIBRARY.uploading") }}
          </v-btn>
        </v-card-actions>
      </v-card>
      <v-overlay :value="loader">
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </v-overlay>
    </v-dialog>
  </div>
</template>
<script>
import axios from "axios";
import { FILE_ENDPOINT } from "./Browser";
import { mediaLibraryMixins } from "@/view/mixins/mediaLibraryMixins.js";
/*export const initialFormData = () => ({
        files: [],
    });*/
export default {
  name: "FileUploadModalForm",
  props: ["fileUploadModalData", "actualDirectory"],
  mixins: [mediaLibraryMixins],
  data: () => ({
    loader: false,
    files: [],
    config: {
      //maxFileSize: 2097152,
      maxFileSize: 128000000,
      maxFilesCount: 15,
      accept:
        "image/png, image/jpeg, image/bmp, image/webp, application/pdf, application/msword, application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/gzip, audio/mpeg, video/mp4, video/mpeg, application/vnd.rar, application/rtf, \n\
                        image/svg+xml, audio/webm, video/webm, application/vnd.ms-excel, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/zip, application/x-7z-compressed",
    },
  }),
  watch: {
    fileUploadModalData: {
      deep: true,
      handler() {
        //this.files = Object.assign({}, initialFormData());
        this.files = [];
      },
    },
  },
  methods: {
    handleCloseFileUploadModalForm() {
      this.$emit("closeFileUploadModalForm");
    },
    handleFileUploadModalForm() {
      console.log(this.actualDirectory);

      this.$refs.fileUploadForm.validate();
      if (this.fileUploadFormValid) {
        const formData = new FormData();
        for (let file of this.files) {
          formData.append("files[]", file, file.name);
        }
        /*let path = this.actualDirectory;
                    path = path.replace(ROOT_DIRECTORY, '');*/
        formData.append("path", this.actualDirectory);
        this.loader = true;
        axios
          .post(FILE_ENDPOINT, formData, {
            headers: {
              "Content-Type": "multipart/form-data",
            },
          })
          .then(() => {
            this.$emit("closeFileUploadModalForm");
            this.loader = false;
          })
          .catch((error) => {
            console.log(error);
            this.loader = false;
          });
      }
    },
    ruleMaxFiles(value) {
      if (
        !!value &&
        value.some((file) => file.size > this.config.maxFileSize)
      ) {
        return (
          this.$t("MEDIA_LIBRARY.file_upload_max_size") +
          ": " +
          this.bytesToSize(this.config.maxFileSize)
        );
      }

      if (!!value && value.length > this.config.maxFilesCount)
        return (
          this.$t("MEDIA_LIBRARY.file_upload_max_count") +
          ": " +
          this.config.maxFilesCount
        );
      return true;
    },
  },
  creaated() {},
};
</script>
