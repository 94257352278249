<template>
  <v-dialog
    v-model="customFieldInputData.dialog"
    max-width="550px"
    persistent
    :retain-focus="false"
  >
    <v-card>
      <v-card-title class="headline">
        <v-row>
          <v-col cols="12" sm="12" md="12">
            <span class="headline">{{ $t("FORMS.edit") }} </span>
            <v-btn @click="handleCloseModalForm" icon class="close-button">
              <v-icon> mdi-close</v-icon>
            </v-btn>
          </v-col>
        </v-row>
      </v-card-title>
      <v-card-text>
        <v-row v-if="customFieldInputData">
          <v-col
            cols="12"
            sm="12"
            md="12"
            v-for="field in customFieldInputData.inputFields"
            :key="field.name"
          >
            <!-- !! {{ file[field.name] }} !! -->
            <v-text-field
              v-if="field.type == 'text' && file"
              v-model="file[field.name]"
              :label="$t(field.label)"
            ></v-text-field>
            <v-textarea
              v-if="field.type == 'textarea' && file"
              v-model="file[field.name]"
              :label="$t(field.label)"
            ></v-textarea>
            <b-form-datepicker
              v-if="field.type == 'datepicker' && file"
              v-model="file[field.name]"
              class="mb-2"
            ></b-form-datepicker>
            <!-- <div v-if="field.type == 'media_selector'">
              <v-btn
                color="primary"
                class="ma-2 white--text"
                @click="handleOpenMediaSelectModal"
                :disabled="files.length > 0"
              >
                {{ $t("FORM_INPUT_NAMES.select_icon") }}
                <v-icon right dark> mdi-cloud-upload </v-icon>
              </v-btn>

              <v-row>
                <v-col
                  v-for="(file, index) in files"
                  :key="file.path"
                  :class="column_class"
                >
                  <v-card>
                    <v-img
                      v-if="previewable_mime_types.includes(file.mime_type)"
                      :src="getFile(file)"
                      height="175"
                      style="cursor: pointer"
                      :title="file.name"
                      @click="() => showImg(index)"
                    ></v-img>
                    <a v-else :href="getFile(file)" target="_blank">
                      <v-img
                        :src="getIcon(file)"
                        :title="file.name"
                        height="175"
                        contain
                      />
                    </a>
                    <v-card-actions style="height: 40px">
                      <v-spacer></v-spacer>

                      <v-icon
                        small
                        color="primary"
                        @click="handleInfoBox(file)"
                      >
                        mdi-information-outline
                      </v-icon>
                      <v-icon
                        small
                        color="primary"
                        @click="deleteItem({ type: 'FILE', path: file.path })"
                      >
                        mdi-delete
                      </v-icon>
                    </v-card-actions>
                  </v-card>
                </v-col>
              </v-row>

              <DeleteModalDialog
                :dialogDelete="dialogDelete"
                @closeDelete="closeDelete"
                @deleteItemConfirm="handleDeleteItemConfirm"
              >
              </DeleteModalDialog>
            </div> -->
          </v-col>
        </v-row>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn color="primary" text @click="handleCloseModalForm">{{
          $t("FORMS.cancel")
        }}</v-btn>
        <v-btn color="primary" @click="handleSaveModalForm">
          {{ $t("FORMS.save") }}
        </v-btn>
        <v-spacer></v-spacer>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
// import SelectMediaModalForm from "@/view/media_library/SelectMediaModalForm.vue";
// import Swal from "sweetalert2";
// import DeleteModalDialog from "@/view/components/DeleteModalDialog";
import { customFieldMixins } from "@/view/mixins/customFieldMixins.js";
// import VueEasyLightbox from "vue-easy-lightbox";
export default {
  name: "CustomInputsModalDialog",
  props: ["customFieldInputData"],
  components: {
    // SelectMediaModalForm,
    // DeleteModalDialog,
    // VueEasyLightbox,
  },
  mixins: [customFieldMixins],
  data() {
    return {
      testField: "",
      // file: {},
      file: null,
      // files: [],
      mediaModalData: {
        dialog: false,
      },
      // customFieldValue: [],
      column_class: "col-6 col-md-3 col-lg-4 col-xl-6",
      // previewable_mime_types: [
      //   "image/png",
      //   "image/jpeg",
      //   "image/x-ms-bmp",
      //   "image/webp",
      //   "image/gif",
      //   "image/svg+xml",
      // ],
      // dialogDelete: false,
      // editedItem: null,
    };
  },
  // computed: {
  //   vue_easy_lightbox_imgs() {
  //     if (!this.files) {
  //       return [];
  //     }

  //     let previewArray = [];
  //     this.files.forEach((item) => {
  //       if (this.previewable_mime_types.includes(item.mime_type)) {
  //         previewArray.push({ src: this.getFile(item), title: item.filename });
  //       }
  //     });
  //     return previewArray;
  //   },
  // },
  // watch: {
  //   customFieldInputData: {
  //     deep: true,
  //     handler(value) {
  //       console.log("value !!!!!", value);
  //       if (value.file) {
  //         this.file = value.file;
  //         // this.files = value.file.files || [];
  //       }
  //     },
  //   },
  // },
  methods: {
    handleCloseModalForm() {
      this.$emit("handleCloseModalForm");
    },

    handleSaveModalForm() {
      // this.file.files = this.files;
      this.$emit("handleSaveModalForm", this.file);
    },

    // handleOpenMediaSelectModal() {
    //   this.mediaModalData.dialog = true;
    // },
    // handleCloseMediaModalForm() {
    //   this.mediaModalData.dialog = false;
    // },

    // handleInfoBox(file) {
    //   Swal.fire({
    //     title: `<strong>${this.$t("ALERT.file_info_title")}</strong>`,
    //     icon: "info",
    //     width: "600px",
    //     html:
    //       `<b>${this.$t("ALERT.created_at")}:</b> ` +
    //       file.created_at +
    //       "<br>" +
    //       `<b>${this.$t("ALERT.name")}:</b> ` +
    //       file.name +
    //       "<br>" +
    //       `<b>${this.$t("ALERT.mime_type")}:</b> ` +
    //       file.mime_type +
    //       "<br>" +
    //       `<b>${this.$t("ALERT.path")}:</b>
    //       <a href="` +
    //       this.getFile(file) +
    //       `" target="_blank">` +
    //       this.getFile(file) +
    //       `</a>` +
    //       "<br>" +
    //       `<b>${this.$t("ALERT.size")}:</b> ` +
    //       file.size +
    //       "<br>",
    //     showCloseButton: true,
    //     focusConfirm: false,
    //     confirmButtonText: "OK",
    //     confirmButtonColor: "#e33354",
    //     infoColor: "#e33354",
    //   });
    // },

    // showImg(index) {
    //   this.vue_easy_lightbox_index = index;
    //   this.vue_easy_lightbox_visible = true;
    // },
    // handleVueEasyLightboxHide() {
    //   this.vue_easy_lightbox_visible = false;
    // },
    // getFile(file) {
    //   return process.env.VUE_APP_BACKEND_URL + "/" + file.path;
    // },
    // deleteItem(item) {
    //   this.editedItem = Object.assign({}, item);
    //   this.dialogDelete = true;
    // },

    // handleDeleteItemConfirm() {
    //   this.files = this.files.filter(
    //     (value) => value.path != this.editedItem.path
    //   );
    //   this.dialogDelete = false;
    // },

    // closeDelete() {
    //   this.editedItem = null;
    //   this.dialogDelete = false;
    // },

    // handleSaveMediaModalForm(selectedFiles) {
    //   selectedFiles.forEach((item) => {
    //     let add = true;
    //     this.files.forEach((cFV) => {
    //       if (cFV.path == item.path) {
    //         add = false;
    //       }
    //     });
    //     if (add) {
    //       this.files.push(item);
    //     }
    //   });
    //   // // this.handleChangeValue(this.customFieldValue);
    //   this.handleCloseMediaModalForm();
    // },
  },

  mounted() {
    this.file = this.customFieldInputData.file;
  },
};
</script>
